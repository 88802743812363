import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';
import { css } from 'emotion';
import { rgba } from 'polished';
import PropTypes from 'prop-types';

import { KeyboardArrowDownOutlined } from '@material-ui/icons';

import { themeVars } from '../../../config/styles';

import Title from '../../Common/Title';

const Faq = ({ question, answer }) => {
  const [isToggled, setIsToggled] = useState(false);

  const cssFaq = css`
    position: relative;
    display: block;
    width: 100%;
    margin: 0 1.5rem 2rem;
    padding: 1.5rem 2rem;
    background: #f6f6f6;
  `;

  const cssArrow = css`
    position: absolute;
    right: 2rem;
    top: 0;

    font-size: 2.4rem !important;
    color: ${themeVars.root.primary};
    display: block;
    transition: 250ms ease-in-out all;

    transform: rotate(${isToggled && '180deg'});

    &:hover {
      color: ${themeVars.root.secondary};
    }
  `;

  const cssQuestion = css`
    position: relative;
    display: block;
    width: 100%;
    text-align: left;

    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    padding: 0;

    &:first-of-type {
      margin-top: 0;
    }

    h5 {
      transition: all 0.3s ease-in-out;
      margin-bottom: 0;
      font-weight: 500;
      color: ${themeVars.root.primary};

      color: ${(isToggled && themeVars.root.primary) || rgba(themeVars.root.dark, 0.5)};

      &:hover {
        color: ${(isToggled && themeVars.root.primary) || rgba(themeVars.root.dark, 1)};
      }
    }
  `;

  const cssAnswer = css`
    font-family: ${themeVars.root.headerFontFamily};
    -webkit-font-smoothing: antialiased;

    margin-top: ${isToggled ? '2rem' : '0'};
  `;

  function handleToggle() {
    setIsToggled(isToggled === false);
  }

  return (
    <div className={cssFaq}>
      <button className={cssQuestion} onClick={handleToggle}>
        <Title size={5}>{question}</Title>
        <KeyboardArrowDownOutlined css={cssArrow} />
      </button>

      <Collapse in={isToggled} collapsedHeight="0">
        <div className={cssAnswer} dangerouslySetInnerHTML={{ __html: answer }} />
      </Collapse>
    </div>
  );
};

Faq.propTypes = {
  answer: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
};

export default Faq;

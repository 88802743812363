import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'react-grid-system';
import { css } from 'emotion';
import { breakup } from '../../../config/breakpoints';

import Title from '../../Common/Title';
import Subtitle from '../../Common/Subtitle';

const ContentFourCol = ({ subtitle, title, children }) => {
  const cssWrapper = css`
    padding: 6rem 0;

    ${breakup.medium`padding: 8rem 0;`}
    ${breakup.large`padding: 10rem 0;`}
  `;
  return (
    <section className={cssWrapper}>
      <Container fluid>
        <Row>
          <Col md={10} push={{ md: 1 }}>
            <Title size={2}>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </Col>
        </Row>
        <Row>
          <Col md={10} push={{ md: 1 }}>
            {children}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

ContentFourCol.defaultProps = {
  subtitle: null,
};

ContentFourCol.propTypes = {
  /** The title for the section */
  title: PropTypes.string.isRequired,

  /** The subtitle for the section */
  subtitle: PropTypes.string,

  /**  List of items using the <ItemList> component */
  children: PropTypes.element.isRequired,
};

export default ContentFourCol;

import React from 'react';
import { Container, Row, Col, Visible } from 'react-grid-system';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { themeVars } from '../../../config/styles';
import { breakup } from '../../../config/breakpoints';
import { parseAbsoluteUrl } from '../../../utils';

import Text from './Text';
import Title from '../../Common/Title';
import Content from '../../Common/Content';
import Subtitle from '../../Common/Subtitle';
import ButtonDark from '../../Common/ButtonDark';

const ContentImageText = ({
  hasButton,
  buttonUrlType,
  buttonUrlType2,
  buttonUrlType3,
  buttonUrlPage,
  buttonUrlPage2,
  buttonUrlPage3,
  buttonUrlExternal,
  buttonUrlExternal2,
  buttonUrlExternal3,
  buttonLabel,
  buttonLabel2,
  buttonLabel3,
  layout,
  bgColor,
  bgImage,
  contentblock,
  subtitle,
  title,
}) => {
  const cssWrapper = css`
    background-color: ${bgColor};
  `;

  const cssImageWrapper = css`
    position: relative;
    line-height: 0;

    img {
      margin: 0;
      padding: 0;
      min-width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;

      max-height: 40rem;

      ${breakup.medium`
        max-height: 65rem;
        height: 100%;
      `}

      ${breakup.large`
        max-height: 90rem;
      `}

      ${breakup.xlarge`
        max-height: 80rem;
      `}
    }
  `;

  const cssNoPadding = css`
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow: hidden;
  `;

  const cssButtonWrapper = css`
    display: block;
    margin-top: 4rem;
    line-height: 4.5;

    & a {
      margin-right: 15px;
      min-height: 54px;
    }
  `;

  return (
    <>
      {layout === 'imageLeft' && (
        <section className={cssWrapper}>
          <Container fluid className={cssNoPadding}>
            <Row nogutter>
              <Col md={12} lg={6} className={cssImageWrapper}>
                <img src={bgImage} alt="1" />
              </Col>
              <Col md={12} lg={6} className="pad-top pad-bottom">
                <Text>
                  <Title size={2}>{title}</Title>
                  {subtitle && <Subtitle>{subtitle}</Subtitle>}

                  <Content color={themeVars.root.dark} content={contentblock} />

                  <div className={cssButtonWrapper}>
                    {/* External */}
                    {hasButton && buttonUrlType === 'external' && (
                      <ButtonDark variant="outlined" href={buttonUrlExternal} target="_blank">
                        {buttonLabel}
                      </ButtonDark>
                    )}

                    {hasButton &&
                      buttonUrlType2 === 'external' &&
                      (buttonUrlExternal2 && (
                        <ButtonDark variant="outlined" href={buttonUrlExternal2}>
                          {buttonLabel2}
                        </ButtonDark>
                      ))}

                    {hasButton &&
                      buttonUrlType3 === 'external' &&
                      (buttonUrlExternal3 && (
                        <ButtonDark variant="outlined" href={buttonUrlExternal3}>
                          {buttonLabel3}
                        </ButtonDark>
                      ))}

                    {/* Internal */}
                    {hasButton &&
                      buttonUrlType === 'page' &&
                      (buttonUrlPage && (
                        <Link to={parseAbsoluteUrl(buttonUrlPage)}>
                          <ButtonDark variant="outlined">{buttonLabel}</ButtonDark>
                        </Link>
                      ))}

                    {hasButton &&
                      buttonUrlType2 === 'page' &&
                      (buttonUrlPage2 && (
                        <Link to={parseAbsoluteUrl(buttonUrlPage2)}>
                          <ButtonDark variant="outlined">{buttonLabel2}</ButtonDark>
                        </Link>
                      ))}

                    {hasButton &&
                      buttonUrlType3 === 'page' &&
                      (buttonUrlPage3 && (
                        <Link to={parseAbsoluteUrl(buttonUrlPage3)}>
                          <ButtonDark variant="outlined">{buttonLabel3}</ButtonDark>
                        </Link>
                      ))}
                  </div>
                </Text>
              </Col>
            </Row>
          </Container>
        </section>
      )}

      {layout === 'imageRight' && (
        <section className={cssWrapper}>
          <Container fluid className={cssNoPadding}>
            <Row nogutter>
              <Visible xs sm md>
                <Col xs={12} lg={6} className={cssImageWrapper}>
                  <img src={bgImage} alt="1" />
                </Col>
              </Visible>
              <Col xs={12} lg={6} className="pad-top pad-bottom">
                <Text>
                  <Title size={2}>{title}</Title>
                  {subtitle && <Subtitle>{subtitle}</Subtitle>}

                  <Content color={themeVars.root.dark} content={contentblock} />

                  <div className={cssButtonWrapper}>
                    {/* External */}
                    {hasButton && buttonUrlType === 'external' && (
                      <ButtonDark variant="outlined" href={buttonUrlExternal}>
                        {buttonLabel}
                      </ButtonDark>
                    )}

                    {hasButton &&
                      buttonUrlType2 === 'external' &&
                      (buttonUrlExternal2 && (
                        <ButtonDark variant="outlined" href={buttonUrlExternal2}>
                          {buttonLabel2}
                        </ButtonDark>
                      ))}

                    {hasButton &&
                      buttonUrlType3 === 'external' &&
                      (buttonUrlExternal3 && (
                        <ButtonDark variant="outlined" href={buttonUrlExternal3}>
                          {buttonLabel3}
                        </ButtonDark>
                      ))}

                    {/* Internal */}
                    {hasButton &&
                      buttonUrlType === 'page' &&
                      (buttonUrlPage && (
                        <Link to={parseAbsoluteUrl(buttonUrlPage)}>
                          <ButtonDark variant="outlined">{buttonLabel}</ButtonDark>
                        </Link>
                      ))}

                    {hasButton &&
                      buttonUrlType2 === 'page' &&
                      (buttonUrlPage2 && (
                        <Link to={parseAbsoluteUrl(buttonUrlPage2)}>
                          <ButtonDark variant="outlined">{buttonLabel2}</ButtonDark>
                        </Link>
                      ))}

                    {hasButton &&
                      buttonUrlType3 === 'page' &&
                      (buttonUrlPage3 && (
                        <Link to={parseAbsoluteUrl(buttonUrlPage3)}>
                          <ButtonDark variant="outlined">{buttonLabel3}</ButtonDark>
                        </Link>
                      ))}
                  </div>
                </Text>
              </Col>
              <Visible lg xl>
                <Col xs={12} lg={6} className={cssImageWrapper}>
                  <img src={bgImage} alt="2" />
                </Col>
              </Visible>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

ContentImageText.defaultProps = {
  bgColor: '#FFF',
  hasButton: false,
  layout: 'imageLeft',
  subtitle: null,
  buttonLabel: '',
};

ContentImageText.propTypes = {
  /** Should the button be displayed */
  hasButton: PropTypes.bool,

  /** Label for the button */
  buttonLabel: PropTypes.string,

  /** Main content for the text section */
  content: PropTypes.string.isRequired,

  /** The background colour of the section */
  bgColor: PropTypes.string,

  /** The background image src */
  bgImage: PropTypes.string.isRequired,

  /** The configuration of the layout. The prop accepts either imageLeft or imageRight */
  layout: PropTypes.string,

  /** Title of the text section */
  title: PropTypes.string.isRequired,

  /** Subtitle of the text section */
  subtitle: PropTypes.string,
};

export default ContentImageText;

import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { rgba } from 'polished';
import { breakup } from '../../../config/breakpoints';

import quoteIcon from '../../../assets/icons/quote.svg';

import { themeVars } from '../../../config/styles';

const ContentQuote = ({ quote, bgImage }) => {
  const cssBackground = css`
    background: url(${bgImage}) center center no-repeat;
    background-size: cover;
    min-height: 583px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: 0 4rem;
    font-family: ${themeVars.root.headerFontFamily};

    ${breakup.medium`padding:0 8rem;`}
    ${breakup.large`padding:0 12rem;`}
    ${breakup.xlarge`padding:0 15rem;`}

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: ${rgba(themeVars.root.darkAlt, 0.7)};
      z-index: -1;
    }
  `;

  const cssQuote = css`
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.5;
    padding: 15px 0;
    color: #fff;

    ${breakup.medium`font-size: 3rem;`}
    ${breakup.large`font-size: 5rem;`}

    &:before {
      content: url(${quoteIcon});
      display: flex;
      justify-content: center;
      margin-bottom: 70px;
    }

    &:after {
      content: url(${quoteIcon});
      display: flex;
      justify-content: center;
      margin-top: 70px;
      transform: rotate(180deg);
    }
  `;

  return (
    <section className={cssBackground}>
      <blockquote className={cssQuote}>{quote}</blockquote>
    </section>
  );
};

ContentQuote.propTypes = {
  /** Background image URL for the section */
  bgImage: PropTypes.string.isRequired,

  /** The quote for the section */
  quote: PropTypes.string.isRequired,
};

export default ContentQuote;

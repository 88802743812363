import React from 'react';
import { Col } from 'react-grid-system';
import { css } from 'emotion';
import PropTypes from 'prop-types';

import { parseNetlifyUrl } from '../../../utils';
import { themeVars } from '../../../config/styles';
import { breakup } from '../../../config/breakpoints';

import Card from '../../Common/Card';

const DownloadItem = ({ buttonLabel, title, description, url, xs, sm, md, lg, xl }) => {
  const cssWrapper = css`
    margin-bottom: 50px;
  `;

  const cssTitle = css`
    font-size: 28px;
    color: #4e5061;
    margin: 0 0 20px;
  `;

  const cssContent = css`
    margin-bottom: 40px;

    p {
      margin: 0;
    }
  `;

  const cssButton = css`
    transition: all 0.3s ease-in-out;
    color: ${themeVars.root.secondary};
    font-size: 1.6rem;
    font-weight: 600;
    font-family: ${themeVars.root.headerFontFamily};

    ${breakup.large`font-size: 1.8rem;`}

    &:hover {
      color: ${themeVars.root.primary};
    }
  `;

  return (
    <Col className={cssWrapper} xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <div>
        <Card>
          <h3 className={cssTitle}>{title}</h3>
          <p className={cssContent}>{description}</p>
          <a aria-label={buttonLabel} className={cssButton} href={parseNetlifyUrl(url)}>
            {buttonLabel}
          </a>
        </Card>
      </div>
    </Col>
  );
};

DownloadItem.defaultProps = {
  buttonLabel: 'Download',
  xs: 12,
  sm: null,
  md: 4,
  lg: 3,
  xl: null,
};

DownloadItem.propTypes = {
  /** Short description of the item */
  description: PropTypes.string.isRequired,

  /** The label for the button */
  buttonLabel: PropTypes.string,

  /** The title of the item */
  title: PropTypes.string.isRequired,

  /** The URL for the button */
  url: PropTypes.string.isRequired,

  /** The grid column size */
  xs: PropTypes.number,

  /** The grid column size */
  sm: PropTypes.number,

  /** The grid column size */
  md: PropTypes.number,

  /** The grid column size */
  lg: PropTypes.number,

  /** The grid column size */
  xl: PropTypes.number,
};

export default DownloadItem;

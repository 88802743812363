import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core/';
import PropTypes from 'prop-types';

import { themeVars } from '../../config/styles';

const ButtonDark = (props) => {
  const { classes, children } = props;

  return (
    <Button {...props} className={classes.root}>
      {children}
    </Button>
  );
};

// MUI Overrides
const styles = {
  root: {
    display: 'inline-block',
    borderRadius: 0,
    borderWidth: 3,
    fontFamily: themeVars.root.bodyFontFamily,
    borderColor: themeVars.root.secondary,
    color: themeVars.root.secondary,
    padding: '1rem 3rem',
    fontWeight: 400,
    fontSize: '1.6rem',
    textTransform: 'none',
    textDecoration: 'none',
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: themeVars.root.secondary,
      color: themeVars.root.light,
      textDecoration: 'none',
    },
  },
};

ButtonDark.defaultProps = {
  variant: 'outlined',
};

ButtonDark.propTypes = {
  /** Material UI classes for style overrides */
  classes: PropTypes.shape({}).isRequired,

  /** The label for the button */
  children: PropTypes.string.isRequired,

  /** The MUI button type */
  variant: PropTypes.string,
};

export default withStyles(styles)(ButtonDark);

import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import { breakup } from '../../../config/breakpoints';

const Video = ({ url }) => {
  const cssWrapper = css`
    padding: 6rem 0;

    ${breakup.medium`padding: 8rem 0;`}
    ${breakup.large`padding: 10rem 0;`}

    .wrapper {
      position: relative;
      padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    }

    .react-player__preview,
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `;
  return (
    <section className={cssWrapper}>
      <Container fluid>
        <Row>
          <Col md={10} push={{ md: 1 }}>
            <div className="wrapper">
              <ReactPlayer url={url} loop width="100%" height="100%" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

Video.propTypes = {
  /** HTML string of content */
  url: PropTypes.string.isRequired,
};

export default Video;

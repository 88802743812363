import React from 'react';
import { Row } from 'react-grid-system';
import PropTypes from 'prop-types';

const FaqList = ({ children }) => {
  return <Row>{children}</Row>;
};

FaqList.propTypes = {
  /** A list of TeamItem components */
  children: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default FaqList;

import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import { breakup } from '../../../config/breakpoints';

import Title from '../../Common/Title';
import Subtitle from '../../Common/Subtitle';

const ContentTeam = ({ bgColor, children, subtitle, title }) => {
  const cssWrapper = css`
    background-color: ${bgColor};
    padding: 6rem 0;

    ${breakup.medium`padding: 8rem 0;`}
    ${breakup.large`padding: 10rem 0;`}
  `;
  return (
    <section className={cssWrapper}>
      <Container fluid>
        <Row>
          <Col xs={12} md={10} push={{ md: 1 }}>
            <Title size={2}>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}

            {children}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

ContentTeam.defaultProps = {
  bgColor: '#E3E3E4',
  subtitle: null,
};

ContentTeam.propTypes = {
  /** The background colour for the section */
  bgColor: PropTypes.string,

  /** The content of the section */
  children: PropTypes.node.isRequired,

  /** The subtitle of the section */
  subtitle: PropTypes.string,

  /** The title of the section */
  title: PropTypes.string.isRequired,
};

export default ContentTeam;

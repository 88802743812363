import React from 'react';
import { Col, Row } from 'react-grid-system';
import PropTypes from 'prop-types';

const DownloadList = ({ children }) => {
  return (
    <Row>
      <Col md={10} push={{ md: 1 }}>
        <Row>{children}</Row>
      </Col>
    </Row>
  );
};

DownloadList.propTypes = {
  /** List of DownloadItem components */
  children: PropTypes.node.isRequired,
};

export default DownloadList;

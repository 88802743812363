import React from 'react';
import { css } from 'emotion';
import PropTypes from 'prop-types';

import { themeVars } from '../../config/styles';

const Card = ({ children }) => {
  const cssCard = css`
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    padding: 40px 30px;

    position: relative;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0);

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 70px 70px;
      border-color: transparent transparent ${themeVars.root.tertiary} transparent;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &:hover {
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
    }
  `;
  return <div className={cssCard}>{children}</div>;
};

Card.propTypes = {
  /** The content of the card */
  children: PropTypes.node.isRequired,
};

export default Card;

import React from 'react';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import { themeVars } from '../../config/styles';

const Subtitle = ({ children }) => {
  const cssSubtitle = css`
    font-size: 22px;
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 35px;
    max-width: 590px;

    a {
      color: ${themeVars.root.primary};
    }
  `;
  return <div className={cssSubtitle} dangerouslySetInnerHTML={{ __html: children }} />;
};

Subtitle.propTypes = {
  /** The subtitle content  */
  children: PropTypes.string.isRequired,
};

export default Subtitle;

import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import { breakup } from '../../../config/breakpoints';

import Title from '../../Common/Title';
import Content from '../../Common/Content';

const ContentFaq = ({ bgColor, children, content, title }) => {
  const cssWrapper = css`
    background-color: ${bgColor};
    padding: 6rem 0;

    ${breakup.medium`padding: 8rem 0;`}
    ${breakup.large`padding: 10rem 0;`}
  `;
  return (
    <section className={cssWrapper}>
      <Container fluid>
        <Row>
          <Col xs={12} md={10} push={{ md: 1 }}>
            <Title size={2}>{title}</Title>
            {content && <Content content={content} />}

            {children}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

ContentFaq.defaultProps = {
  bgColor: '#ffffff',
  content: null,
};

ContentFaq.propTypes = {
  /** The background colour for the section */
  bgColor: PropTypes.string,

  /** The content of the section */
  children: PropTypes.node.isRequired,

  /** The content of the section */
  content: PropTypes.string,

  /** The title of the section */
  title: PropTypes.string.isRequired,
};

export default ContentFaq;

export const siteVars = {
  cmsUrl: 'http://pratap.dev.vividcreative.co.uk',
  netlifyUrl: 'https://admiring-gates-8f8dbd.netlify.com',
  siteUrl: 'https://www.pratappartnership.co.uk/',
};

/**
 * @function parseNetlifyUrl
 * @description Replace the Netlify URL for the CMS URL. Required for some ACF field types
 *  */
export const parseNetlifyUrl = (content) => {
  return content.replace(siteVars.netlifyUrl, siteVars.cmsUrl);
};

/**
 * @function parseAbsoluteUrl
 * @description Replace the Netlify URL for the CMS URL. Required for some ACF field types
 *  */
export const parseAbsoluteUrl = (content, string) => {
  return content.replace(siteVars.siteUrl, string || '');
};
export default parseNetlifyUrl;

import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css } from 'emotion';
import PropTypes from 'prop-types';

import { themeVars } from '../../config/styles';
import { breakup } from '../../config/breakpoints';

import Title from './Title';
import Subtitle from './Subtitle';

const Section = ({ bgColor, color, children, title, subtitle }) => {
  const cssWrapper = css`
    background-color: ${bgColor};
    padding: 6rem 0;
    color: ${color};

    ${breakup.medium`padding: 8rem 0;`}
    ${breakup.large`padding: 10rem 0;`}
  `;

  return (
    <section className={cssWrapper}>
      <Container fluid>
        <Row>
          <Col md={10} push={{ md: 1 }}>
            <Title size={2} color={color}>
              {title}
            </Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </Col>
        </Row>
      </Container>

      {children}
    </section>
  );
};

Section.defaultProps = {
  color: themeVars.root.dark,
  subtitle: null,
  bgColor: 'inherit',
};

Section.propTypes = {
  /** The background colour of the section */
  bgColor: PropTypes.string,

  /** The color of the text */
  color: PropTypes.string,

  /** The content of the section */
  children: PropTypes.node.isRequired,

  /** The title of the section */
  title: PropTypes.string.isRequired,

  /** The subtitle of the section */
  subtitle: PropTypes.string,
};

export default Section;

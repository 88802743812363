import React from 'react';
import { css } from 'emotion';
import { Col } from 'react-grid-system';
import PropTypes from 'prop-types';

import { themeVars } from '../../../config/styles';

const Card = ({ children }) => {
  const cssCard = css`
    display: block;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    padding: 40px 30px;

    position: relative;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0);

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 70px 70px;
      border-color: transparent transparent ${themeVars.root.primary} transparent;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &:hover {
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
    }

    p {
      color: #45494e;
    }
  `;
  return (
    <Col xs={12} md={6} lg={4}>
      <div className={cssCard}>{children}</div>
    </Col>
  );
};

Card.propTypes = {
  /** The content of the card */
  children: PropTypes.node.isRequired,
};

export default Card;

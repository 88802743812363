import React from 'react';
import { css } from 'emotion';
import PropTypes from 'prop-types';

const CardTitle = ({ children }) => {
  const cssTitle = css`
    color: #4e5061;
    font-size: 28px;
    margin-bottom: 15px;
    min-height: 66px;
  `;

  return <h3 className={cssTitle} dangerouslySetInnerHTML={{ __html: children }} />;
};

CardTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

export default CardTitle;

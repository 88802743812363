import React from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';

// Vars
import { themeVars } from '../../config/styles';

// Components
import ContentFourCol from './ContentFourCol/ContentFourCol';
import ItemList from './ContentFourCol/ItemList';
import Item from './ContentFourCol/Item';
import ContentImageText from './ContentImageText/ContentImageText';
import ContentRegister from './ContentRegister/ContentRegister';
import ContentQuote from './ContentQuote/ContentQuote';
import ContentText from './ContentText/ContentText';
import DownloadList from './ContentDownloads/DownloadList';
import DownloadItem from './ContentDownloads/DownloadItem';
import Section from '../Common/Section';
import ContentTeam from '../ContentModules/ContentTeam/ContentTeam';
import TeamList from '../ContentModules/ContentTeam/TeamList';
import TeamItem from '../ContentModules/ContentTeam/TeamItem';
import Video from '../ContentModules/ContentVideo/Video';
import ContentImageSlider from '../ContentModules/ContentImageSlider/ContentImageSlider';
import ContentFaq from '../ContentModules/ContentFaq/ContentFaq';
import FaqList from '../ContentModules/ContentFaq/FaqList';
import Faq from '../ContentModules/ContentFaq/Faq';
import ContentOpportunities from '../ContentModules/ContentOpportunities/ContentOpportunities';
import JobList from '../ContentModules/ContentOpportunities/JobList';
import Job from '../ContentModules/ContentOpportunities/Job';
import CardTitle from '../Common/CardTitle';

const ContentModules = ({ module }) => {
  switch (module.__typename) {
    case 'WordPressAcf_video':
      return <Video url={module.youtubeurl} />;
    case 'WordPressAcf_downloads':
      return (
        <Section title={module.title} subtitle={module.subtitle}>
          <DownloadList>
            {module.downloads.map((download) => (
              <DownloadItem
                buttonLabel="Download"
                description={download.description}
                key={shortid.generate()}
                md={4}
                title={download.title}
                url={download.file.source_url}
              />
            ))}
          </DownloadList>
        </Section>
      );
    case 'WordPressAcf_fourcol':
      return (
        <ContentFourCol title={module.title} subtitle={module.subtitle}>
          <ItemList>
            {module.columns.map((column) => (
              <Item key={shortid.generate()} title={column.title} content={column.content} />
            ))}
          </ItemList>
        </ContentFourCol>
      );
    case 'WordPressAcf_register':
      return (
        <ContentRegister
          key={shortid.generate()}
          bgColor={themeVars.root.primary}
          color={themeVars.root.light}
          subtitle={module.subtitle}
          title={module.title}
        />
      );

    case 'WordPressAcf_quote':
      return (
        <ContentQuote
          key={shortid.generate()}
          quote={module.quote}
          bgImage={module.bg_image.localFile.childImageSharp.fluid.src}
        />
      );
    case 'WordPressAcf_content_image':
      return (
        <ContentImageText
          key={shortid.generate()}
          bgColor="#f2f2f3"
          bgImage={module.image.localFile.childImageSharp.fluid.src}
          buttonLabel={module.button_label}
          buttonLabel2={module.button_label_2}
          buttonLabel3={module.button_label_3}
          buttonUrlExternal={module.button_url_external}
          buttonUrlExternal2={module.button_url_external_2}
          buttonUrlExternal3={module.button_url_external_3}
          buttonUrlPage={module.button_url_page}
          buttonUrlPage2={module.button_url_page_2}
          buttonUrlPage3={module.button_url_page_3}
          buttonUrlType={module.button_url_type}
          buttonUrlType2={module.button_url_type_2}
          buttonUrlType3={module.button_url_type_3}
          contentblock={module.content}
          hasButton={module.button}
          layout={module.layout}
          subtitle={module.subtitle}
          title={module.title}
        />
      );
    case 'WordPressAcf_text':
      return <ContentText title={module.title} content={module.content} />;
    case 'WordPressAcf_team_members':
      return (
        <ContentTeam title={module.title} subtitle={module.subtitle}>
          <TeamList>
            {module.members.map((member) => (
              <TeamItem
                imageUrl={member.member[0].acf.image.localFile.childImageSharp.fluid}
                altText={member.member[0].acf.image.alt_text}
                key={shortid.generate()}
                xl={3}
                lg={4}
                md={6}
                name={member.member[0].post_title}
                title={member.job_title}
                url={member.profile_url || member.profile_url_external}
                urlType={member.profile_link_type}
                xs={12}
              />
            ))}
          </TeamList>
        </ContentTeam>
      );
    case 'WordPressAcf_imageslider':
      return (
        <ContentImageSlider title={module.title} content={module.content} images={module.images} />
      );
    case 'WordPressAcf_frequentlyasked':
      return (
        <ContentFaq title={module.title} content={module.content}>
          <FaqList>
            {module.faqs.map((faq) => (
              <Faq key={shortid.generate()} question={faq.question} answer={faq.answer} />
            ))}
          </FaqList>
        </ContentFaq>
      );
    case 'WordPressAcf_opportunities':
      return (
        <ContentOpportunities title={module.title}>
          <JobList>
            {module.jobs.map((job) => (
              <Job key={shortid.generate()}>
                <a aria-label={job.title} href={job.link} target="_blank" rel="noopener noreferrer">
                  <CardTitle>{job.title}</CardTitle>
                  <div dangerouslySetInnerHTML={{ __html: job.content }} />
                </a>
              </Job>
            ))}
          </JobList>
        </ContentOpportunities>
      );

    default:
      return null;
  }
};

ContentModules.propTypes = {
  module: PropTypes.shape({}).isRequired,
};

export default ContentModules;

import React from 'react';
import { Row } from 'react-grid-system';
import PropTypes from 'prop-types';

const TeamList = ({ children }) => {
  return <Row>{children}</Row>;
};

TeamList.propTypes = {
  /** A list of TeamItem components */
  children: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default TeamList;

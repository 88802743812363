import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css } from 'emotion';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';
import Slider from 'react-slick';
import shortid from 'shortid';

import { ChevronRightOutlined, ChevronLeftOutlined } from '@material-ui/icons';

// Assets
import 'slick-carousel/slick/slick.css';

import { themeVars } from '../../../config/styles';
import { breakup } from '../../../config/breakpoints';

import Title from '../../Common/Title';
import Content from '../../Common/Content';

const ContentImageSlider = ({ bgColor, content, title, images }) => {
  const cssWraps = css`
    position: relative;
    display: block;

    background-color: ${bgColor};
    padding: 6rem 0 12rem;

    > div {
      padding-right: 0 !important;
    }

    ${breakup.medium`padding: 8rem 0 16rem;`}
    ${breakup.large`padding: 10rem 0 20rem;`}
  `;

  const cssArrow = css`
    font-size: 4.8rem !important;
    color: #000;
    display: block;
    transition: 250ms ease-in-out all;

    &:hover {
      color: ${themeVars.root.secondary};
    }
  `;

  const cssArrowLeft = css`
    position: absolute;
    left: -20px;
    bottom: -64px;
    cursor: pointer;
    z-index: 10;
  `;

  const cssArrowRight = css`
    position: absolute;
    left: 30px;
    bottom: -64px;
    cursor: pointer;
    z-index: 10;
  `;

  const cssSlide = css`
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  `;

  const cssWrapper = css`
    position: relative;
    line-height: 0;
    display: block;
    clear: both;

    .slick-dots {
      position: absolute;
      list-style: none;
      left: 5rem;
      width: auto;
      bottom: -7rem;

      & > li {
        display: inline-block;
        margin-right: 0.5rem;

        &:hover {
          .hexagon {
            background-color: ${themeVars.root.primary};
          }

          .hexagon:before {
            border-bottom: 3.46px solid ${themeVars.root.primary};
          }

          .hexagon:after {
            border-top: 3.46px solid ${themeVars.root.primary};
          }
        }
      }
    }

    .hexagon {
      position: relative;
      display: inline-block;

      width: 1rem;
      height: 1rem;
      background: ${themeVars.root.secondary};
      border-radius: 10rem;
    }

    .slick-active {
      .hexagon {
        background-color: ${themeVars.root.primary};
      }

      .hexagon:before {
        border-bottom: 3.46px solid ${themeVars.root.primary};
      }

      .hexagon:after {
        border-top: 3.46px solid ${themeVars.root.primary};
      }
    }
  `;

  function CustomLeftArrow({ onClick }) {
    return (
      <div css={cssArrowLeft}>
        <ChevronLeftOutlined css={cssArrow} onClick={onClick} />
      </div>
    );
  }

  function CustomRightArrow({ onClick }) {
    return (
      <div css={cssArrowRight}>
        <ChevronRightOutlined css={cssArrow} onClick={onClick} />
      </div>
    );
  }

  const settings = {
    arrows: true,
    autoplay: false,
    customPaging: () => <div className="hexagon" />,
    dots: true,
    draggable: false,
    infinite: true,
    nextArrow: <CustomRightArrow />,
    prevArrow: <CustomLeftArrow />,
    slidesToScroll: 1,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 1640,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className={cssWraps}>
      <Container fluid>
        <Row>
          <Col xs={11} md={8} push={{ md: 1 }} lg={6}>
            {title ? <Title size={2}>{title}</Title> : ''}
            {content ? <Content content={content} /> : ''}
          </Col>
        </Row>
        <Row nogutter>
          <Col xs={12} md={11} push={{ md: 1 }}>
            <Slider {...settings} css={cssWrapper}>
              {images &&
                images.map((slide) => {
                  return (
                    <div css={cssSlide} key={shortid.generate()}>
                      <Img fluid={slide.image.localFile.childImageSharp.fluid} />
                    </div>
                  );
                })}
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

ContentImageSlider.defaultProps = {
  onClick: () => null,
  images: null,
  bgColor: '#FAFAFA',
  content: null,
  title: null,
};

ContentImageSlider.propTypes = {
  onClick: PropTypes.func,
  images: PropTypes.arrayOf(PropTypes.shape({})),
  bgColor: PropTypes.string,
  content: PropTypes.string,
  title: PropTypes.string,
};

export default ContentImageSlider;

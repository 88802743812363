import React from 'react';
import { css } from 'emotion';
import { Container, Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';
import { breakup } from '../../../config/breakpoints';

const Text = ({ children }) => {
  const cssWrapper = css`
    min-height: 25rem;

    ${breakup.medium`min-height:40rem`}
    ${breakup.large`min-height:60rem`}
    ${breakup.xlarge`min-height:65rem`}
  `;

  return (
    <Container>
      <Row className={cssWrapper} align="center">
        <Col md={10} lg={8} push={{ md: 1, lg: 2 }}>
          {children}
        </Col>
      </Row>
    </Container>
  );
};

Text.propTypes = {
  /** Content for the section */
  children: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default Text;

import React from 'react';
import { css } from 'emotion';
import { Container, Col, Row } from 'react-grid-system';
import PropTypes from 'prop-types';

import { themeVars } from '../../../config/styles';

import Section from '../../Common/Section';
import RegisterForm from '../../Contact/ContactForm';

const ContentRegister = ({ bgColor, color, title, subtitle }) => {
  const cssWrapper = css`
    color: #fff;
  `;

  return (
    <Section
      bgColor={bgColor}
      clasName={cssWrapper}
      color={color}
      subtitle={subtitle}
      title={title}
    >
      <Container fluid>
        <Row nogutter>
          <Col xs={12} md={10} push={{ md: 1 }}>
            <RegisterForm />
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

ContentRegister.defaultProps = {
  bgColor: '#FFF',
  color: themeVars.root.dark,
  subtitle: null,
};

ContentRegister.propTypes = {
  /** The background colour of the section */
  bgColor: PropTypes.string,

  /** The color of the text for the section */
  color: PropTypes.string,

  /** The subtitle for the section */
  subtitle: PropTypes.string,

  /** The title for the section */
  title: PropTypes.string.isRequired,
};

export default ContentRegister;

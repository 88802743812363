import React from 'react';
import { Row } from 'react-grid-system';
import PropTypes from 'prop-types';

const ItemList = ({ children }) => <Row>{children}</Row>;

ItemList.propTypes = {
  /** A list of content <Item> components */
  children: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default ItemList;

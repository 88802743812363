import React from 'react';
import { Col } from 'react-grid-system';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import { themeVars } from '../../../config/styles';
import { breakup } from '../../../config/breakpoints';

import Card from '../../Common/Card';

const TeamItem = ({ imageUrl, altText, name, title, url, urlType, xs, sm, md, lg, xl }) => {
  const cssWrapper = css`
    margin-bottom: 4rem;

    ${breakup.medium`margin-bottom: 6rem;`}

    &:hover {
      h3,
      a {
        color: ${themeVars.root.primary};
      }

      img {
        transform: scale(1.1);
      }
    }
  `;

  const cssImage = css`
    vertical-align: bottom;
    width: 100%;
    max-width: 100%;
    margin: 0;
  `;

  const cssName = css`
    transition: all 0.3s, ease-in-out;
    font-size: 28px;
    color: #4e5061;
    margin: 0;
  `;

  const cssSubtitle = css`
    color: #45494e;
    font-family: ${themeVars.root.headerFontFamily};
  `;

  const cssButton = css`
    transition: all 0.3s ease-in-out;
    color: ${themeVars.root.secondary};
    font-size: 1.6rem;
    font-weight: 500;
    font-family: ${themeVars.root.headerFontFamily};

    ${breakup.large`font-size: 1.8rem;`}

    &:hover {
      color: ${themeVars.root.primary};
    }
  `;

  return (
    <Col className={cssWrapper} xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <div>
        {urlType === 'external' && (
          <a href={url} target="_blank" rel="noopener noreferrer" aria-label={name}>
            <Img className={cssImage} fluid={imageUrl} alt={altText} />
          </a>
        )}

        {urlType === 'page' && (
          <Link to={url}>
            <Img className={cssImage} fluid={imageUrl} alt={altText} />
          </Link>
        )}

        <Card>
          {urlType === 'external' && (
            <a href={url} target="_blank" rel="noopener noreferrer" aria-label={name}>
              <h3 className={cssName}>{name}</h3>
            </a>
          )}
          {urlType === 'page' && (
            <Link to={url}>
              <h3 className={cssName}>{name}</h3>
            </Link>
          )}
          <p className={cssSubtitle}>{title}</p>

          {urlType === 'external' && (
            <a
              className={cssButton}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={name}
            >
              Read more
            </a>
          )}

          {urlType === 'page' && (
            <Link to={url} className={cssButton}>
              Read more
            </Link>
          )}
        </Card>
      </div>
    </Col>
  );
};

TeamItem.defaultProps = {
  altText: 'Team member',
  xs: 12,
  sm: null,
  md: 4,
  lg: 3,
  xl: null,
};

TeamItem.propTypes = {
  /** The ALT tag text for the image */
  altText: PropTypes.string,
  /** The URL for the image */
  imageUrl: PropTypes.shape({}).isRequired,

  /** The name of the team member */
  name: PropTypes.string.isRequired,

  /** The job title of the team member */
  title: PropTypes.string.isRequired,

  /** The URL for the button */
  url: PropTypes.string.isRequired,

  /** The grid column size */
  xs: PropTypes.number,

  /** The grid column size */
  sm: PropTypes.number,

  /** The grid column size */
  md: PropTypes.number,

  /** The grid column size */
  lg: PropTypes.number,

  /** The grid column size */
  xl: PropTypes.number,

  /** The type of navigation to use */
  urlType: PropTypes.string.isRequired,
};

export default TeamItem;

import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css } from 'emotion';
import PropTypes from 'prop-types';

import { breakup } from '../../../config/breakpoints';

const ContentText = ({ title, content, bgColor }) => {
  const cssWrapper = css`
    background-color: ${bgColor};
    padding: 6rem 0;

    ${breakup.medium`padding: 8rem 0;`}
    ${breakup.large`padding: 10rem 0;`}
  `;
  return (
    <section className={cssWrapper}>
      <Container fluid>
        <Row>
          <Col md={10} push={{ md: 1 }}>
            {title ? <h3>{title}</h3> : ''}
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

ContentText.defaultProps = {
  title: '',
  bgColor: '#f2f2f3',
};

ContentText.propTypes = {
  /** HTML string of content */
  title: PropTypes.string,
  content: PropTypes.string.isRequired,

  /** String value containing a valid color. Uses a hex value by default */
  bgColor: PropTypes.string,
};

export default ContentText;

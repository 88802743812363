import React from 'react';
import { Col } from 'react-grid-system';
import PropTypes from 'prop-types';
import { css } from 'emotion';

import Title from '../../Common/Title';

const cssWrapper = css`
  margin-bottom: 4rem;
`;

const Item = ({ title, content }) => {
  return (
    <Col className={cssWrapper} xs={12} md={6} lg={6} xl={3}>
      <Title size={3}>{title}</Title>
      <p>{content}</p>
    </Col>
  );
};

Item.propTypes = {
  /** The title of the item content */
  title: PropTypes.string.isRequired,

  /** The content of the item */
  content: PropTypes.string.isRequired,
};

export default Item;
